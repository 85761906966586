import classnames from 'classnames'

import '../../../scss/react/loading.scss'

interface IconProps {
    children: React.ReactNode
    className?: string
}

export const LoadingIcon = ({ className, children }: IconProps) => (
    <div className={classnames('loading-icon', className)}>
        {children}
        <div className="loading-ring" />
    </div>
)

interface Props {
    className?: string
    center?: boolean
}

const LoadingAnimation = ({ className, center }: Props) => (
    <div
        className={classnames('loading-ripple', className, {
            'loading-ripple-center': center,
        })}
    >
        <div />
        <div />
    </div>
)

export default LoadingAnimation
