import React from 'react'
import { createRoot } from 'react-dom/client'
import Suspense from './js/components/suspense'
import './sentry'

import './scss/firstload.scss'

const App = React.lazy(() => import('./js'))
const rootEl = document.getElementById('react')

if (rootEl) {
    const root = createRoot(rootEl)
    root.render(
        <Suspense>
            <App />
        </Suspense>
    )
}
