import * as Sentry from '@sentry/react'
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom'

import { useEffect } from 'react'

const sentryFilterTerms = [
    'NoneType', // graphql error
    'p161', // gtm.js error
    'ChunkLoadError', // common network problem error,
    'chunk', // common network problem error,
    'Network', // common network problem error,
    'netwerk', // common network problem error,
    'removeChild', // removeChild error
]

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
    ],

    sampleRate: parseInt(process.env.REACT_APP_SENTRY_SAMPLERATE || '1', 10),

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: parseInt(process.env.REACT_APP_SENTRY_SAMPLERATE || '1', 10) / 100,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        /^\//,
        /^https:\/\/(api|notifs|login)\.(familynet|familinet)\.(dev|nl|care|co\.uk|com|be)/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: parseInt(process.env.REACT_APP_SENTRY_SAMPLERATE || '1', 10) / 10000,
    replaysOnErrorSampleRate: parseInt(process.env.REACT_APP_SENTRY_SAMPLERATE || '1', 10) / 100,
    beforeSend: (event) => {
        // if transaction id is shown allways send to sentry
        if (event?.tags?.transaction_id) {
            return event
        }

        // test if event message or exception values contain filter terms
        const inFilter = (message: string | undefined) =>
            sentryFilterTerms.some((str) => message?.includes(str))

        // filter events with specific terms in message
        if (event?.message && inFilter(event.message)) {
            console.warn('skip this error', event)
            return null
        }

        // filter events with specific terms in exception values
        if (event?.exception?.values?.some(({ value }) => inFilter(value))) {
            console.warn('skip this error', event)
            return null
        }

        console.warn('sentry report:', event)
        if (process.env.NODE_ENV === 'development') {
            return null
        }

        return event
    },
})
