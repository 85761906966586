import React from 'react'
import { Remark } from 'react-remark'
import { t, TranslationValues, Phrase } from '../helpers/i18n'

interface TransProps {
    label: Phrase
    className?: string
    markdown?: boolean
    values?: TranslationValues
}

const Trans = ({ className, label, markdown, values }: TransProps) => {
    if (!label) return null

    if (markdown) {
        return <Remark>{t(label, values)}</Remark>
    }

    if (className) {
        return <span className={className}>{t(label, values)}</span>
    }

    return <React.Fragment>{t(label, values)}</React.Fragment>
}

export default Trans
