import Polyglot from 'node-polyglot'

// validates all strings are written in enlgish and added to nl_NL.json file
import translations from '../../i18n/nl-NL.json'
export type Phrase = keyof typeof translations
export type TranslationValues = Record<string, string | number>

export const polyglot = new Polyglot()

// this function is overloaded so translation can be double translated when it is first translated with values
// eg <Page title={t('Page %{page}', { page: 1 })} /> as title is translated again later
// function t(phrase: Phrase, values?: never): string
function t(phrase: Phrase, values?: TranslationValues): Phrase {
    if (!polyglot.has(phrase)) {
        if (process.env.REACT_APP_I18N_GATHER === 'true') {
            console.error(`missing translation label ${phrase}`, values)
        }
        polyglot.extend({ [phrase]: phrase })
    }

    // if one of the args is object
    if (typeof values === 'object') {
        return polyglot.t(phrase, values)
    }

    return polyglot.t(phrase)
}

function has(phrase: Phrase): boolean {
    return polyglot.has(phrase)
}

export { has, t }

export const capitalize = ([first, ...rest]: string, locale = navigator.language) => {
    return first.toLocaleUpperCase(locale) + rest.join('')
}
