import React from 'react'

import Trans from '../trans'
import LoadingAnimation from './animation'

import '../../../scss/react/loading.scss'

interface Props {
    children?: React.ReactNode
}

const LoadingPage = ({ children }: Props) => (
    <div className="loading-page">
        <LoadingAnimation />
        <div>
            {children}
            {!children && (
                <p>
                    <Trans label="Loading..." />
                </p>
            )}
        </div>
    </div>
)

export const LoadingFullPage = (props: Props) => (
    <div>
        <nav></nav>
        <main>
            <LoadingPage {...props} />
        </main>
    </div>
)

export default LoadingPage
